import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { isMobile } from 'react-device-detect';

import '../Home/home.css'
import { Paper } from '@material-ui/core';
function SendConf() {
    const history = useHistory()
    // console.log(params.get('phone'))
    const [config, setconfig] = useState({
        phone: '',
        text: ''
    })

    const sendMessage = () => {
        navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
        if (navigator.vibrate) {
            navigator.vibrate([30, 10, 60]);
        }
        const url= `?phone=${config?.phone}&text=${config.text}`
        isMobile ? window.location.href = encodeURI(`whatsapp://send${url}`) :
            window.location.href = encodeURI(`https://web.whatsapp.com/send${url}`)
    }

    const goBack = () => {
        navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
        if (navigator.vibrate) {
            navigator.vibrate(60);
        }
        history.push(`/?edit=true&phone=${config.phone}&text=${config.text}`)
    }

    React.useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        setconfig(pv => ({
            ...pv,
            phone: params?.get('phone'),
            text: params?.get('text')
        }))
        params?.get('phone') || history.push(`/`)
    }, [history])
    return (
        <>
            <Typography variant="h6" color="initial">
                Are you sure to send message on
            </Typography>
            <Typography onClick={goBack} variant="h6" color="primary">
                {`+${config?.phone?.trim()}`}
            </Typography>
            {config?.text?.length >0 && <Paper style={{padding:10}} variant="outlined">
            { config?.text}
            </Paper>}
            <br /><br />
            <Button onClick={goBack} size="small" variant="outlined" color="default">
                <CreateOutlinedIcon fontSize="small" /> &nbsp;
                Edit
            </Button>
            <Button onClick={sendMessage} disableElevation className={`lrg_btn`} size="large" fullWidth={true} variant="contained" color="primary">
                Yes, Message
            </Button>
        </>
    )
}

export default SendConf
