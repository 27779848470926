import React from 'react'
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider, Paper } from '@material-ui/core'
// import { AnimatedSwitch } from 'react-router-transition';

// import SendConf from './Components/SendConf/SendConf'
import { Container } from '@material-ui/core';
import SendConf from './Components/SendConf/SendConf'

//lazy loading
//constant
const Header = React.lazy(() => import('./Components/Header/Header'))
//routes
const Home = React.lazy(() => import('./Components/Home/Home'))
const Footer = React.lazy(() => import('./Components/Bottom/Footer'))
const Error = React.lazy(() => import('./Components/Error/Error'))
const Bulk = React.lazy(() => import('./Components/Bulk/Bulk'))


function App() {

  const [darkmode, setdarkmode] = React.useState(localStorage.getItem('dark'))
  const newtheme = createMuiTheme({
    palette: {
      type: true ? 'dark' : 'light',
      primary: {
        main: darkmode ? '#00897B' : "#00897B"
      },
      secondary: {
        main: darkmode ? '#fff' : "#054840"
      },
      background: {
        paper: darkmode ? '#121212' : '#054840',
        default: darkmode ? '#121212' : '#054840'
      }
    },
    typography: {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      button: {
        textTransform: "capitalize",
        fontWeight: 600
      },
      body1: {
        fontFamily: "Wavehaus Book, Roboto, Helvetica, Arial, sans-serif",
      },
      h5: {
        fontFamily: "Wavehaus Semibold, Roboto, Helvetica, Arial, sans-serif",
      },
      h6: {
        fontFamily: "Wavehaus Semibold, Roboto, Helvetica, Arial, sans-serif",
      }
    },
    shape: {
      borderRadius: 6
    }
  });

  React.useEffect(() => {
    setdarkmode(localStorage.getItem('dark') === 'true' ? true : false)
  }, [])

  return (
    <>
      <ThemeProvider theme={newtheme}>
        <Paper className={`cntnr`} elevation={0}>
          <Router>
            <Header />
            <Container className="main_cnt" maxWidth="sm">
              <Switch
                // atEnter={{ opacity: 0 }}
                // atLeave={{ opacity: 0 }}
                // atActive={{ opacity: 1 }}
                // className="switch-wrapper"

              >
                <Route exact path="/" component={Home} />
                <Route path="/send" component={SendConf} />
                <Route path="/bulk" component={Bulk} />
                <Route path="*" component={Error} />
              </Switch>
            </Container>
              <Footer/>
          </Router>
        </Paper>
      </ThemeProvider>

    </>
  );
}

export default App;
